.addExampleButton {
    margin-top: 2rem;
}

.list {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    margin-bottom: 4rem;
}

.link {
    display: block;
    text-decoration: none;
    border: var(--dominantLanguageColor) solid var(--thickBorder);
    padding: 0.5rem;
    background: var(--white);
}

.link:hover {
    --dominantLanguageColor: var(--orange);
}

.link:hover .exampleTextOriginal,
.link:hover .exampleTextTranslated {
    --borderColor: var(--orange);
}

.link:hover .headingOriginal,
.link:hover .headingTranslated {
    text-decoration: underline;
}

.example {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.buttonExample {
    font-size: var(--fontSizeS);
    font-style: italic;
    color: var(--disabledGrey);
}

.headingOriginal {
    font-size: inherit;
    margin: 0;
    color: var(--lang-color);
}

.headingOriginal[lang='de'] {
    hyphens: auto;
}

.cover {
    --height: 8rem;
}

.coverContainer {
    margin-top: 0.5rem;
}

.footer {
    display: flex;
    justify-content: space-between;
    font-size: var(--fontSizeS);
    font-style: italic;
    margin-top: 0.5rem;
}
