.buttonWrapper {
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
    --borderFocusColor: var(--dominantLanguageColor);
}

.buttonWrapper:before {
    content: '';
    position: relative;
    z-index: 1;
    display: block;
    height: 3rem;
    width: 3rem;
    margin-top: calc(-0.5rem - var(--thickBorder));
    margin-right: 0.5rem;
    background-image: linear-gradient(
            to right,
            transparent 0,
            transparent calc(100% - var(--thickBorder)),
            var(--borderFocusColor) calc(100% - var(--thickBorder)),
            var(--borderFocusColor) calc(100%)
        ),
        linear-gradient(
            45deg,
            transparent 0,
            transparent calc(50% - var(--thickBorder) / 2),
            var(--borderFocusColor) calc(50% - var(--thickBorder) / 2),
            var(--borderFocusColor) calc(50% + var(--thickBorder) / 2),
            var(--backgroundColor) calc(50% + var(--thickBorder) / 2)
        );
}

.buttonWrapperWithFocus {
    composes: buttonWrapper;
    --borderFocusColor: var(--orange);
}

.shareBox {
    --w: calc(var(--thickBorder) * 1);
    --l: 2rem;

    position: relative;
    border: var(--thickBorder) solid var(--orange);
    padding: 1rem;
    background: var(--white);
}

.shareBox:before {
    content: '';
    pointer-events: none;
    position: absolute;
    visibility: hidden;
    top: calc(var(--thickBorder) * -1);
    right: calc(var(--thickBorder) * -1);
    bottom: calc(var(--thickBorder) * -1);
    left: calc(var(--thickBorder) * -1);
    background: linear-gradient(90deg, var(--green) 50%, transparent 50%),
        linear-gradient(90deg, var(--green) 50%, transparent 50%),
        linear-gradient(0deg, var(--green) 50%, transparent 50%),
        linear-gradient(0deg, var(--green) 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: var(--l) var(--w), var(--l) var(--w), var(--w) var(--l), var(--w) var(--l);
    animation: border-dance 0.3s 20 linear;
}

@keyframes border-dance {
    0% {
        background-position: 0 0, var(--l) 100%, 0 var(--l), 100% 0;
        visibility: visible;
    }
    100% {
        background-position: var(--l) 0, 0 100%, 0 0, 100% var(--l);
        visibility: visible;
    }
}

.shareBoxClose {
    position: absolute;
    border: var(--thickBorder) solid var(--orange);
    background: var(--white);
    height: 1rem;
    width: 1rem;
    top: -0.5rem;
    right: -0.5rem;
    cursor: pointer;
}

.shareBoxClose:before,
.shareBoxClose:after {
    content: '';
    height: var(--thickBorder);
    width: 80%;
    position: absolute;
    background: var(--orange);
    top: 50%;
    left: 50%;
}

.shareBoxClose:before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.shareBoxClose:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}
