.addBioHint {
    color: var(--placeholderGrey);
    font-style: italic;
}

.socialMedia {
    margin-bottom: 1rem;
    line-height: var(--lineHeightParagraph);
}

.bio {
    white-space: pre-wrap;
}

.editSettings h3 {
    margin-top: 2rem;
}
