.heading {
    margin: 0;
}

.header {
    margin: 1rem 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.headingInner {
    background: var(--lang-color);
    box-shadow: 0.5rem 0 0 var(--lang-color), -0.5rem 0 0 var(--lang-color);
}

.headingInner.hasBackground {
    color: var(--fontColorOnColor);
}

.subHeading,
.topHeading {
    margin-top: 0;
    margin-bottom: 0.5rem;
    margin-left: -0.5rem;
    font-size: 1rem;
    font-style: italic;
    font-weight: normal;
}

.topHeading + .topHeading {
    margin-top: 1rem;
    margin-left: 0;
}

.topHeading + .heading {
    margin-left: 0.5rem;
}

.topHeading + .topHeading + .heading {
    margin-left: 0;
    margin-top: 1rem;
}

.topHeadingLink {
    text-decoration: none;
    background: var(--lang-color);
    padding: 0.5rem;
    position: relative;
    color: var(--fontColorOnColor);
}

.topHeadingLink:hover:before {
    content: '';
    position: absolute;
    bottom: 0.4rem;
    left: 0.3rem;
    right: 0.3rem;
    background: var(--black);
    height: var(--thickBorder);
}

.capitalize {
    display: inline-block;
}

.capitalize::first-letter {
    text-transform: uppercase;
}

.subline {
    line-height: var(--lineHeightParagraph);
    margin-top: 0.5rem;
}

@media (min-width: 600px) {
    .header {
        min-height: calc(var(--sidebarWidth) - 1rem);
        margin-bottom: 4rem;
    }
}

.rightHandSideSublineContainer {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
}

@media (min-width: 900px) {
    .rightHandSideSublineContainer {
        grid-template-columns: 1fr 14rem;
    }

    .rightHandSide {
        margin-top: 0.5rem;
        text-align: right;
    }
}
