.container {
    --height: 10rem;
    --minWidth: calc(var(--height) / 2);
    --borderColor: var(--dominantLanguageColor);
    --pageColor: var(--white);

    position: relative;
    --d: calc((var(--thickBorder) + var(--thickBorder)) + var(--thickBorder));
    --d2: calc((var(--d) * 2));
    box-shadow: var(--d) var(--d) 0 calc(var(--thickBorder) * -1) var(--pageColor),
        var(--d) var(--d) 0 0px var(--borderColor), var(--d2) var(--d2) 0 calc(var(--thickBorder) * -1) var(--pageColor),
        var(--d2) var(--d2) 0 0px var(--borderColor);
    border: solid var(--borderColor) var(--thickBorder);
    margin: 0 calc(var(--thickBorder) * 6) calc(var(--thickBorder) * 6) 0;
    display: inline-block;
}

.container:before {
    content: '';
    position: absolute;
    background: var(--borderColor);
    top: 100%;
    left: 0;
    height: var(--thickBorder);
    width: 1rem;
    transform: rotate(45deg);
    transform-origin: left;
}

.cover {
    display: block;
    height: var(--height);
    min-width: var(--minWidth);
    object-fit: cover;
}

.fallback {
    width: calc(var(--height) * 0.7);
    height: var(--height);
    padding: 0.1rem;
    font-size: var(--fontSizeXS);
    background: var(--disabledGreyBackground);
    text-align: center;
    display: flex;
    align-items: center;
    font-style: italic;
    font-family: var(--monospaceFont);
    hyphens: auto;
}
