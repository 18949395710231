.icon {
    height: 1rem;
    vertical-align: text-top;
}

.label {
    margin-bottom: 1rem;
}

.button {
    display: inline-block;
}
