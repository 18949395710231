.logos {
    display: flex;
    flex-wrap: wrap;
    margin-top: -2rem;
}

.logoContainer:not(:last-child) {
    margin-right: 4rem;
}

.logoContainer {
    margin-top: 2rem;
}

.logoImg {
    height: 5rem;
    background-size: contain;
}

.logoImg.small {
    max-width: 5rem;
}

.logoHeading {
    font-size: var(--fontSizeS);
    font-style: italic;
    font-weight: normal;
    margin: 0 0 1rem;
}

.logoWrapper {
    display: inline-block;
}

.logoWrapper + .logoWrapper {
    margin-left: 2rem;
}
