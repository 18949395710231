.adminComment,
.defintion {
    font-family: var(--paragraphFont);
    line-height: var(--lineHeightParagraph);
    white-space: pre-wrap;
}

.defintion {
    font-style: italic;
}

.guidelines {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.guideline {
    display: block;
    background: var(--orange);
    text-decoration: none;
    font-weight: bold;
    padding: 0.5rem;
    line-height: 1;
}

.guideline:hover {
    text-decoration: underline;
}

.guidelineHeading {
    margin-bottom: 0.5rem;
}

.relatedTermsHeading {
    margin-bottom: 0.5rem;
}

.relatedTermsList {
    display: flex;
    gap: 0.5rem 1rem;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
}

.relatedTerm:not(:hover) {
    text-decoration: none;
}
