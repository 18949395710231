.section {
    --boxColor: var(--orange);
    --indicatorSize: 1.5rem;
    position: relative;
    border: solid var(--boxColor);
    border-width: 0 var(--thickBorder) var(--thickBorder);
    margin: 0 0 1rem;
    background-color: rgba(255, 255, 255, 0.7);
}

.toggleButton {
    position: relative;
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    text-align: left;
    padding: 1rem;
    width: 100%;
}

.heading {
    background: var(--boxColor);
    margin: 0;
    font-weight: bold;
}

.content {
    padding: 1rem 1rem 3rem;
    backdrop-filter: blur(10px);
    position: relative;
}

.content *:first-child {
    margin-top: 0;
}

.content *:last-child {
    margin-bottom: 0;
}

.intro {
    font-family: var(--paragraphFont);
    line-height: var(--lineHeightParagraph);
    font-weight: 600;
}

.intro + .children {
    margin-top: 1rem;
}

.moreButtonContainer {
    position: relative;
    display: flex;
    justify-content: center;
    color: var(--boxColor);
}

@property --gap {
    syntax: '<number>'; /* <- defined as type number for the transition to work */
    initial-value: 0;
    /* inherits: false; */
}

.moreButton {
    position: absolute;
    top: 0.5rem;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    color: inherit;
}

.moreButton:before {
    --width: 2.5rem;
    content: '';
    position: absolute;
    right: calc(50% - var(--width) / 2);
    top: 100%;
    width: var(--width);
    height: 2.5rem;
}

.moreButton:hover {
    font-weight: 600;
}

.arrow {
    --size: 1rem;
    --gap: -0.4rem;
    --gapWide: -0.5rem;
    left: 50%;
    bottom: calc(var(--size) * -1 - 1rem);
    background: var(--backgroundColor);
    transform: translateX(-50%) rotate(45deg);
}

.arrow,
.arrow:before,
.arrow:after {
    position: absolute;
    width: var(--size);
    height: var(--size);
    border: var(--boxColor) solid;
    border-width: 0 var(--thickBorder) var(--thickBorder) 0;
}

.open .arrow,
.open .arrow:before,
.open .arrow:after {
    border-width: var(--thickBorder) 0 0 var(--thickBorder);
}

.open .arrow:before {
    visibility: hidden;
}

.open .arrow {
    --gap: -0.45rem;
    --gapWide: -0.55rem;
    margin-bottom: 0.1rem;
}

.arrow:before,
.arrow:after {
    content: '';
    display: block;
    transform: translate(var(--gap), var(--gap));
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-play-state: paused;
}

.arrow:before {
    transform: translate(var(--gap), var(--gap));
    animation-name: arrowBefore;
}

.arrow:after {
    transform: translate(calc(var(--gap) * 2), calc(var(--gap) * 2));
    animation-name: arrowAfter;
}

.moreButton:hover .arrow:before {
    animation-play-state: running;
}

.moreButton:hover .arrow:after {
    animation-play-state: running;
}

@keyframes arrowBefore {
    from {
        transform: translate(calc(var(--gap) * 2), calc(var(--gap) * 2));
    }
    to {
        transform: translate(calc(var(--gapWide) * 2), calc(var(--gapWide) * 2));
    }
}

@keyframes arrowAfter {
    from {
        transform: translate(var(--gap), var(--gap));
    }
    to {
        transform: translate(var(--gapWide), var(--gapWide));
    }
}

.container {
    display: grid;
    grid-gap: 2rem;
    width: var(--fullWidthInNarrowCollumn);
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    align-items: start;
    margin: 2rem 0;
}

.container .section {
    margin: 0;
}
