.unsetButtonContainer {
    position: relative;
    display: inline-block;
}

.unsetButton {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: block;
    cursor: pointer;
    font-family: inherit;
    background: transparent;
    border: none;
    color: transparent;
}

.unsetButton:hover {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(0.2rem);
    color: var(--black);
}

.container {
    --thumbSize: 1rem;
    --thumbShadowWidth: 0;

    position: relative;
    max-width: 20rem;
    width: 100%;
    font-weight: normal;
}

.container.small {
    --thumbSize: 0.7rem;
    margin: 0;
    width: 5rem;
}

.container:focus-within {
    --thumbShadowWidth: 0.1rem;
}

.ratings {
    position: relative;
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: flex-end;
    padding-top: var(--thickBorder);
}

.small .ratings {
    height: 1.7rem;
}

.rating {
    position: relative;
    background: var(--dominantLanguageColor);
    flex: 1;
    box-shadow: 0 0 0 var(--thickBorder) var(--white);
}

.ratingInner {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: var(--fontSizeS);
    bottom: 100%;
    padding: 0.2rem;
    cursor: default;
}

.ratingInner.inside {
    bottom: auto;
    top: 0;
}

.small .ratingInner {
    visibility: hidden;
}

.rating + .rating {
    margin-left: var(--thickBorder);
}

.emtpyMessage {
    font-size: var(--fontSizeS);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-style: italic;
    color: var(--disabledGrey);
}

.rangeInput {
    --columnCount: 5;
    --thumbOpacity: 1;
    display: block;
    position: relative;
    z-index: 2;
    width: calc(100% - 0.3rem);
    margin: var(--thickBorder) 0;
    width: 100%;
    background: var(--dominantLanguageColor);
    height: var(--thickBorder);
    -webkit-appearance: none;
}

.rangeInput:focus {
    outline: none;
    background: var(--orange);
}

.rangeInput.unset {
    --thumbOpacity: 0.5;
}

.small .rangeInput.unset,
.medium .rangeInput:disabled {
    --thumbOpacity: 0;
}

.rangeInput:not(:disabled)::-webkit-slider-thumb {
    cursor: pointer;
}

.rangeInput:not(:disabled)::-moz-range-thumb {
    cursor: pointer;
}

.rangeInput::-webkit-slider-thumb {
    width: var(--thumbSize);
    height: var(--thumbSize);
    border-radius: 50%;
    background: var(--orange);
    -webkit-appearance: none;
    opacity: var(--thumbOpacity);
    box-shadow: 0 0 0 var(--thumbShadowWidth) var(--orange);
}

.rangeInput::-moz-range-thumb {
    width: var(--thumbSize);
    height: var(--thumbSize);
    border-radius: 50%;
    background: var(--orange);
    border: none;
    margin-top: calc(var(--thickBorder) * -0.5);
    opacity: var(--thumbOpacity);
    box-shadow: 0 0 0 var(--thumbShadowWidth) var(--orange);
}

.permanentSliderLabel,
.userUsageDisplay {
    margin-top: 0.5rem;
    width: 100%;
}

.permanentSliderLabel {
    display: flex;
    justify-content: space-between;
}

.hiddenLabel {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

.userUsageDisplay {
    font-size: var(--fontSizeS);
    text-align: center;
}

.large .userUsageDisplay {
    font-size: inherit;
    font-style: italic;
}
