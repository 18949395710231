.container {
    font-family: var(--paragraphFont);
}

.container ol,
.container ul {
    line-height: var(--lineHeightParagraph);
}

.container li {
    margin-bottom: 0.5rem;
}

.container h2,
.container h3 {
    font-family: var(--monospaceFont);
    margin-top: 2rem;
    line-height: 1.4;
}

.container h3 {
    font-size: 1.1rem;
}

.container :global(.wp-block-buttons) {
    display: flex;
    gap: 1rem;
}

.container :global(.wp-block-button__link) {
    display: inline-block;
    font-size: inherit;
    font-family: var(--monospaceFont);
    color: var(--black);
    border: var(--thinBorder) var(--black) solid;
    padding: 0.5rem;
    background-color: var(--backgroundColor);
    text-decoration: none;
}

.container :global(.wp-block-button__link):focus {
    outline: none;
}

.container :global(.wp-block-button__link):focus:not(:focus-visible) {
    color: var(--black);
    background-color: var(--backgroundColor);
}

.container :global(.wp-block-button__link):hover:not(:disabled),
.container :global(.wp-block-button__link):focus {
    color: var(--white);
    cursor: pointer;
    background-color: var(--black);
}

.container :global(.boxes > .wp-block-group__inner-container) {
    width: var(--fullWidthInNarrowCollumn);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 2rem;
    margin: 2rem 0;
    align-items: flex-start;
}

.container :global(.boxes > .wp-block-group__inner-container > .wp-block-group) {
    padding: 1rem;
    border: solid var(--thickBorder) var(--green);
    background: var(--white);
}

.container :global(.boxes > .wp-block-group__inner-container > .wp-block-group h2),
.container :global(.boxes > .wp-block-group__inner-container > .wp-block-group h3) {
    margin: -1rem -1rem 1rem;
    padding: 1rem;
    background: var(--green);
    color: var(--blackDark);
}

.container :global(.boxes-big > .wp-block-group__inner-container) {
    grid-template-columns: repeat(auto-fill, minmax(min(350px, calc(100vw - 4rem)), 1fr));
}

.container :global(.boxes > .wp-block-group__inner-container ul) {
    padding-left: 1rem;
}

.container :global(.wp-block-image) {
    margin: 2rem 0;
}

.container :global(.wp-block-image):not(:global(.is-resized)) img {
    width: 100%;
    height: auto;
}

.container :global(.wp-block-embed) {
    margin: 1rem 0;
}

.container :global(.wp-block-embed iframe) {
    width: 100%;
}

.container :global(.wp-block-embed-youtube) {
    position: relative;
    padding-bottom: calc(9 / 16 * 100%);
}

.container :global(.wp-block-embed-youtube iframe) {
    position: absolute;
    height: 100%;
}
