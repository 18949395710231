.container {
    --height: 10rem;
    --minWidth: calc(var(--height) / 2);
    --borderColor: var(--dominantLanguageColor);
    --stripWidth: 0.5rem;

    display: inline-block;
    background: var(--dominantLanguageColor);
    border: solid var(--borderColor) var(--thickBorder);
    padding: 0 var(--stripWidth);
    background: repeating-linear-gradient(
        to bottom,
        var(--dominantLanguageColor) 0rem,
        var(--dominantLanguageColor) var(--stripWidth),
        var(--white) var(--stripWidth),
        var(--white) calc(var(--stripWidth) * 2)
    );
}

.cover {
    display: block;
    height: var(--height);
    min-width: var(--minWidth);
    object-fit: cover;
    border-width: 0 var(--thickBorder);
    border-color: var(--dominantLanguageColor);
    border-style: solid;
}

.fallback {
    width: calc(var(--height) * 0.7);
    line-height: 1;
    height: var(--height);
    padding: 0.1rem;
    background: var(--disabledGreyBackground);
    text-align: center;
    display: flex;
    align-items: center;
    font-style: italic;
    font-size: var(--fontSizeXS);
    font-family: var(--monospaceFont);
    hyphens: auto;
}
