.inner {
    border: solid var(--dominantLanguageColor) var(--thickBorder);
    background: var(--dominantLanguageColor);
    background: var(--white);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.medium .inner {
    height: 25rem;
}

.inner:hover {
    --dominantLanguageColor: var(--orange);
}

.meta {
    margin-bottom: 0.3rem;
}

.body,
.header {
    padding: 0.5rem;
}

.body {
    flex: 1;
}

.header {
    background: var(--dominantLanguageColor);
}

.tiny .header {
    overflow: hidden;
}

.tiny .body {
    min-width: 10rem;
}

.heading {
    margin: 0;
    font-weight: normal;
    font-style: italic;
    font-size: var(--fontSizeXL);
    overflow: hidden;
    text-overflow: ellipsis;
}

.headingLink {
    color: var(--blackDark);
    text-decoration: none;
}

.section + .section {
    margin-top: 2rem;
}

.definition {
    margin-top: 0;
}

.bodyHeading {
    font-weight: normal;
    font-size: var(--fontSizeM);
    margin: 0.5rem 0;
}

.commentFooter {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    padding: 0.5rem;
    text-align: center;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5rem;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, var(--white) 85%, var(--white) 100%);
}

.smallSummary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.smallSummaryEmpty {
    font-size: var(--fontSizeS);
    color: var(--disabledGrey);
    font-style: italic;
}

.smallSummaryTranslationList {
    padding: 0;
    margin: -0.3rem -0.5rem;
    list-style: none;
}

.smallSummaryTranslationListItem {
    display: inline-block;
    margin: 0.3rem 0.5rem;
}

.footer {
    flex: 0 0 auto;
}

@media (min-width: 700px) {
    .small .inner,
    .tiny .inner {
        flex-direction: row;
    }

    .smallSummary {
        align-items: center;
        flex-direction: row;
    }

    .smallSummaryTranslationList {
        padding: 0 0.5rem;
    }
}

@media (max-width: 700px) {
    .footer {
        margin-top: 0.5rem;
    }
}

@media (min-width: 1000px) {
    .medium .body,
    .medium .header,
    .commentFooter {
        padding: 1rem;
    }
}
