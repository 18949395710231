.container {
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    cursor: text;
}

.containerError {
    box-shadow: 0 0 0 var(--thickBorder) var(--red);
}

.containerDisabled {
    color: var(--disabledGrey);
}

.containerBusy {
    --w: calc(var(--thickBorder) * 1);
    --l: 2rem;
}

.containerBusy:before {
    content: '';
    position: absolute;
    top: calc(var(--thickBorder) * -1);
    right: calc(var(--thickBorder) * -1);
    bottom: calc(var(--thickBorder) * -1);
    left: calc(var(--thickBorder) * -1);
    background: linear-gradient(90deg, var(--green) 50%, transparent 50%),
        linear-gradient(90deg, var(--green) 50%, transparent 50%),
        linear-gradient(0deg, var(--green) 50%, transparent 50%),
        linear-gradient(0deg, var(--green) 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: var(--l) var(--w), var(--l) var(--w), var(--w) var(--l), var(--w) var(--l);
    animation: border-dance 0.3s infinite linear;
}

@keyframes border-dance {
    0% {
        background-position: 0 0, var(--l) 100%, 0 var(--l), 100% 0;
    }
    100% {
        background-position: var(--l) 0, 0 100%, 0 0, 100% var(--l);
    }
}

.container:focus-within {
    box-shadow: 0 0 0 var(--thickBorder) var(--orange);
}

.inputContainer {
    position: relative;
    min-height: 3.7rem;
    height: 100%;
}

.input {
    width: 100%;
    font-family: inherit;
    font-weight: bold;
    border: none;
    background: transparent;
    font-size: inherit;
    padding: 0rem 0.5rem 0.5rem;
    color: inherit;
    opacity: 1;
}

.input:focus {
    outline: none;
}

.label,
.labelEmpty {
    padding: 0.6rem 0.5rem 0.4rem;
    transition: all 0.2s;
    pointer-events: none;
    transform: translateY(0);
}

.inlineButton {
    position: absolute;
    top: 0;
    right: 0.5rem;
    bottom: 0;
    display: flex;
    align-items: center;
}

.error {
    background: var(--red);
    font-size: var(--fontSizeS);
    color: var(--white);
    padding: 0.5rem;
}

.warning {
    composes: error;
    background: var(--orange);
}

.container:not(:focus-within):not(.hasPlaceholder):not(.dontAnimateLabel) .labelEmpty {
    transform: translateY(0.7rem);
}

.ariaLabel {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

.select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 2rem;
    composes: input;
    -webkit-appearance: none;
    cursor: pointer;
    background-image: url('./select_arrow.svg');
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: center right 1rem;
    background-color: transparent;
}

.select:focus {
    outline: none;
}

.textarea,
.textareaDummy {
    composes: input;
    background: transparent;
    font-size: inherit;
    min-height: 100px;
}

.textareaDummy {
    visibility: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.textarea {
    position: absolute;
    height: 100%;
    resize: none;
}

.textAreaWrapper {
    position: relative;
}

.textarea::placeholder,
.input::placeholder {
    color: var(--placeholderGrey);
    font-weight: normal;
}

.textarea:focus {
    outline: none;
}
