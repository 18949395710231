.title {
    font-size: var(--fontSizeL);
}

.heading {
    margin-top: 0;
}

.otherTerms {
    margin-top: 2rem;
}

.buttonContainer {
    margin-top: 2rem;
}

.translationList {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

.guidelines {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

.otherTermsList {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

.manifestoLinkBox {
    padding: 1rem;
    border: solid var(--thickBorder) var(--orange);
    background: var(--white);
}

.manifestoLinkText {
    margin: 0;
}
