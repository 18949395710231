.body {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 2rem;
}

.comments {
    grid-column: 3 / -3;
    margin-top: 2rem;
}

.bookContainer {
    display: flex;
    align-items: flex-start;
    grid-column: span 4;
    margin: -0.5rem;
}

@media (min-width: 751px) {
    .bookContainer.original {
        flex-direction: row-reverse;
        text-align: right;
    }
}

.bookContainer > * {
    margin: 0.5rem;
}

.heading {
    margin: 0;
    font-size: var(--fontSizeM);
}

.definitionList {
    margin: 0;
    font-size: var(--fontSizeS);
}

.definitionListKey {
    margin-top: 0.5rem;
}

.definitionListValue {
    font-style: italic;
    margin: 0 0.5rem;
}

.sourceLink {
    word-break: break-word;
}

.bookIcon {
    --height: 8rem;
}

.snippetOriginal,
.snippetTranslated {
    align-self: flex-start;
}

.snippetOriginal {
    grid-column: 2 / 5;
}

.snippetTranslated {
    grid-column: 5 / -2;
}

@media (max-width: 900px) {
    .snippetOriginal {
        grid-column: 1 / 5;
    }

    .snippetTranslated {
        grid-column: 5 / -1;
    }
}

@media (max-width: 750px) {
    .body {
        grid-gap: 1rem;
    }

    .snippetOriginal,
    .snippetTranslated,
    .bookContainer,
    .comments {
        grid-column: span 8;
    }

    .snippetOriginal,
    .snippetTranslated {
        margin-bottom: 2rem;
    }

    .snippetOriginal {
        grid-row: 2;
    }

    .bookIcon {
        --height: 6rem;
    }

    .snippetTranslated,
    .bookContainer.translated {
        margin-left: 1rem;
    }

    .snippetOriginal,
    .bookContainer.original {
        margin-right: 1rem;
    }
}
