.selected {
    position: relative;
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.selectedMeta {
    margin-left: 1rem;
}

.selectedMetaBottom {
    font-style: italic;
}

.selectedHeading {
    margin: 0;
}

.selectedCancelButtonInline {
    margin-top: 0.5rem;
}
