.terms {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin-top: 0rem;
}

.filters {
    position: sticky;
    z-index: 1;
    top: 0;
    padding-top: 0.5rem;
    margin: -4rem -0.5rem 0;
    height: 4rem;
    background: var(--white);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.filters > * {
    margin: 0.5rem;
}

@media (max-width: 800px) {
    .filters {
        margin-top: -1rem;
        margin-bottom: 1rem;
        top: 4rem;
        height: auto;
    }
}

.loginHint {
    margin-bottom: 1rem;
}

.addButtonBottom {
    margin-top: 2rem;
}
