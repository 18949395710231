.radio {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

.container {
    display: inline-flex;
    border: var(--thickBorder) solid var(--black);
}

.container.disabled {
    border-width: var(--thinBorder);
    border-color: var(--disabledGrey);
}

.container:focus-within {
    border: var(--thickBorder) solid var(--orange);
}

.containerLabel {
    margin-bottom: 0.5rem;
}

.containerLabel.disabled {
    color: var(--disabledGrey);
}

.label {
    display: block;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    background: var(--backgroundColor);
}

.labelStriped {
    composes: label;
}

.labelLangA,
.labelLangB {
    composes: label;
}

.radioWrapper + .radioWrapper .label {
    border-left: var(--thickBorder) solid var(--black);
}

.container:focus-within .radioWrapper + .radioWrapper .label {
    border-left: var(--thickBorder) solid var(--orange);
}

.radio:checked + .label {
    font-weight: bold;
    color: var(--fontColorOnColor);
    background: var(--green);
}

.radio:checked + .labelLangA {
    background: var(--colorLangA);
}

.radio:checked + .labelLangB {
    background: var(--colorLangB);
}

.radio:checked + .labelStriped {
    --sw: 6px;
    background-image: repeating-linear-gradient(
        -45deg,
        var(--colorLangB),
        var(--colorLangB) var(--sw),
        var(--colorLangA) var(--sw),
        var(--colorLangA) calc(var(--sw) * 2)
    );
}

.disabled .label {
    cursor: default;
    color: var(--disabledGrey);
}

.disabled .radio:checked + .label {
    font-weight: normal;
    background: var(--disabledGreyBackgroundDark);
}

.radioWrapper.disabled + .radioWrapper.disabled .label {
    border-left-width: var(--thinBorder);
    border-color: var(--disabledGrey);
}
