.container {
    position: relative;
    border: var(--thickBorder) solid var(--dominantLanguageColor);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1rem 1fr;
    --height: 10rem;
    height: var(--height);
    width: min-content;
    background: var(--white);
}

.image {
    height: calc(var(--height) - 1rem - var(--thickBorder) * 2);
    display: block;
    max-width: calc(var(--height) * 1.3);
    object-fit: cover;
}

@media (max-width: 600px) {
    .image {
        max-width: 33vw;
    }
}

.topBar {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding: 0.2rem;
}

.dots {
    display: flex;
}

.dot {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background: var(--black);
    margin-right: 0.1rem;
}

.addressBar {
    background: var(--black);
    font-size: 0.5rem;
    text-align: center;
    border-width: 0 0.1rem;
    border-color: var(--black);
    border-style: solid;
    color: var(--white);
    overflow: hidden;
    white-space: nowrap;
}

.noImageLabel {
    background: var(--disabledGreyBackground);
    color: var(--disabledGrey);
    font-style: italic;
    font-size: var(--fontSizeXS);
    width: calc(var(--height) * 1);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
}

.logo {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    object-fit: contain;
    top: 1.2rem;
    left: 0.2rem;
    background: var(--white);
}
