.headingHint {
    font-size: var(--fontSizeS);
    font-style: italic;
    color: var(--disabledGrey);
}

.heading {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
}

.headingMain {
    margin-right: 0.5rem;
}
