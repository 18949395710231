.button {
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    font-style: inherit;
    padding: 0;
    display: inline;
    border: none;
    background: none;
    cursor: pointer;
}

.button:hover {
    text-decoration: underline;
}

.underlined {
    text-decoration: underline;
}

.button:disabled {
    text-decoration: none;
    cursor: default;
}
