.list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.itemInner {
    --mainColor: var(--dominantLanguageColor);

    display: flex;
    flex-direction: column;
    background: var(--white);
    border: var(--thickBorder) solid var(--mainColor);
}

.itemInner.clickable {
    cursor: pointer;
}

.itemInner.clickable:hover {
    --mainColor: var(--orange);
}

.link {
    text-decoration: none;
    flex: 0 1 auto;
    min-width: 0;
}

.link:hover {
    text-decoration: underline;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rating {
    margin: 0 calc(var(--thickBorder) * 2);
}

.body {
    padding: 0.5rem;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.value {
    padding: 0.5rem;
    background-color: var(--mainColor);
    margin: 0;
    font-size: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--fontColorOnColor);
}

.noExample {
    margin: 0 0 0.5rem;
    font-size: var(--fontSizeS);
    display: flex;
    align-items: center;
    color: var(--disabledGrey);
    font-style: italic;
}

.noExampleButton {
    margin-right: 0.5rem;
}

.noExampleText {
    visibility: hidden;
}

.item:hover .noExampleText {
    visibility: visible;
}

.addExampleButton {
    height: 5rem;
    width: 3rem;
    background: var(--disabledGreyBackground);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--disabledGrey);
    flex: 0 0 auto;
    font-size: var(--fontSizeM);
    font-style: normal;
}

.addExampleButton:hover {
    color: var(--black);
    border: var(--black) solid var(--thinBorder);
}

.meta {
    padding: 0.5rem;
}

.footer {
    display: flex;
    justify-content: space-between;
    font-size: var(--fontSizeS);
    font-style: italic;
    margin-top: 0.5rem;
}

.translationExampleList {
    margin: -0.25rem;
    padding: 0 0 0.5rem;
    list-style: none;
    display: flex;
    overflow-x: auto;
}

.translationExampleListItem {
    margin: 0.25rem;
}

.exampleIcon {
    --height: 5rem;
}

.small h2 {
    font-size: var(--fontSizeM);
    margin: 0 0 0.5rem;
}

.itemMeta {
    margin-bottom: 0.3rem;
}
