.analysisContainer {
    font-family: var(--paragraphFont);
    min-height: calc(300px - 2rem);
}

@media (min-width: 1100px) {
    .analysisContainer {
        font-size: var(--fontSizeL);
    }
}

.analysisContainer p > * {
    transition: 0.3s opacity, 0.3s filter, 0.1s box-shadow;
}

.analysisContainerWithTooltip p > *:not(.sensitiveWordHovered) {
    opacity: 0.7;
    filter: blur(1px);
}

.analysisContainer p {
    margin: 0;
    white-space: pre-wrap;
}

.sensitiveWord {
    background: var(--lang-color);
    padding: 0.2em;
    font-style: italic;
    cursor: pointer;
    border: none;
    display: inline;
    font-size: inherit;
    font-family: inherit;
}

.sensitiveWordHovered,
.sensitiveWord:hover {
    box-shadow: 0 0 0 2px var(--lang-color);
    text-decoration: underline;
}

.tooltip {
    max-width: 300px;
    font-size: var(--fontSizeS);
}

.tooltip p {
    margin: 0;
}

.tooltip p + p {
    margin-top: 0.5rem;
}

.tooltipDefinition {
    font-style: italic;
}

.textLabel {
    margin-bottom: 0.5rem;
}
