.button {
    background: var(--disabledGreyBackground);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 1rem;
    line-height: var(--lineHeightParagraph);
    border: solid var(--black) var(--thinBorder);
}

.button:hover,
.button:focus {
    border-color: var(--orange);
}
