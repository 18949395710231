.button {
    --buttonBorderColor: var(--black);
    position: relative;
    display: block;
    font-size: inherit;
    font-family: var(--monospaceFont);
    color: var(--black);
    border: var(--thinBorder) var(--buttonBorderColor) solid;
    padding: 0.5rem;
    background-color: var(--backgroundColor);
    text-decoration: none;
}

.button:focus {
    outline: none;
}

.button:focus:not(:focus-visible) {
    color: var(--black);
    background-color: var(--backgroundColor);
}

.button:hover:not(:disabled),
.button:focus {
    color: var(--white);
    cursor: pointer;
    background-color: var(--black);
}

.buttonPrimary {
    --buttonBorderColor: var(--orange);
    border-width: var(--thickBorder);
}

.buttonPrimary:focus:not(:focus-visible) {
    background-color: var(--backgroundColor);
    font-weight: normal;
}

.buttonPrimary:hover:not(:disabled),
.buttonPrimary:focus {
    background-color: var(--buttonBorderColor);
    font-weight: bold;
}

.button:disabled {
    color: var(--disabledGrey);
    border-color: var(--disabledGrey);
}

.small {
    padding: 0.3rem;
    font-size: var(--fontSizeS);
}

.large {
    padding: 0.7rem;
    font-size: var(--fontSizeL);
    font-weight: bold;
}

:global(.inputContainer) + .buttonContainer {
    margin-top: 1rem;
}

.busy {
    --w: calc(var(--thickBorder) * 1);
    --l: 2rem;
}

.busy:before {
    content: '';
    position: absolute;
    top: calc(var(--thickBorder) * -1);
    right: calc(var(--thickBorder) * -1);
    bottom: calc(var(--thickBorder) * -1);
    left: calc(var(--thickBorder) * -1);
    background: linear-gradient(90deg, var(--green) 50%, transparent 50%),
        linear-gradient(90deg, var(--green) 50%, transparent 50%),
        linear-gradient(0deg, var(--green) 50%, transparent 50%),
        linear-gradient(0deg, var(--green) 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: var(--l) var(--w), var(--l) var(--w), var(--w) var(--l), var(--w) var(--l);
    animation: border-dance 0.3s infinite linear;
}

@keyframes border-dance {
    0% {
        background-position: 0 0, var(--l) 100%, 0 var(--l), 100% 0;
    }
    100% {
        background-position: var(--l) 0, 0 100%, 0 0, 100% var(--l);
    }
}

.buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: -0.25rem;
}

.buttonContainer.left {
    justify-content: flex-start;
}

.buttonContainer.center {
    justify-content: center;
}

.buttonContainer .button {
    margin: 0.25rem; /* waiting for gap in flexbox in Safari to happen 😬 */
}
