.container {
    --headerBarHeight: 4rem;

    display: grid;
    height: 100%;
    grid-template-columns: 1fr var(--sidebarWidth) minmax(auto, var(--contentWidth)) 1fr;
    grid-gap: 4rem;
}

@media (max-width: 1400px) {
    .container {
        grid-gap: 3rem;
    }
}

@media (max-width: 1300px) {
    .container {
        grid-gap: 2rem;
    }
}

@media (max-width: 599px) {
    body.bodyMenuOpen {
        overflow: hidden;
    }
}

@media (min-width: 600px) {
    .header,
    .sidebar {
        grid-gap: 1rem;
    }
}

.mobileHeaderBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    padding: 0.5rem;
    background: var(--white);
    display: flex;
    align-items: center;
    height: var(--headerBarHeight);
}

@media (min-width: 600px) {
    .mobileHeaderBar {
        display: none;
    }
}

.mobileHeaderBarLogo {
    flex: 1;
    align-self: stretch;
}

.mobileHeaderBarLogoImage {
    height: 100%;
    margin-right: 0.5rem;
}

.header {
    padding: 2rem 0;
}

@media (max-width: 599px) {
    .header {
        display: none;
    }
}

.logo {
    display: block;
    text-decoration: none;
    grid-column: 2;
}

.logoImg {
    width: 100%;
    height: var(--sidebarWidth);
    object-fit: contain;
    object-position: left;
}

@media (max-width: 599px) {
    .menus {
        display: none;
        position: fixed;
        top: var(--headerBarHeight);
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0.5rem;
        background: var(--white);
        z-index: 10;
        overflow: scroll;
    }

    .menus.open {
        display: block;
    }
}

@media (min-width: 600px) {
    .menus {
        position: sticky;
        top: 0;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        display: flex;
        flex-direction: column;
        height: 100vh;
        z-index: 10;
    }
}

.sidebarInner {
    grid-column: 2;
}

.sidebar {
    margin: 1rem 0;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebarInner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: -0.5rem;
}

.sidebarLink {
    text-decoration: none;
    display: inline-block;
    margin: 0.5rem;
    position: relative;
    text-transform: lowercase;
}

.sidebarLink:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--white);
    box-shadow: 0 0 0 0.3rem var(--white);
    z-index: -1;
}

.sidebarLink:hover,
.sidebarLink:focus,
.sidebarLinkActive {
    outline: none;
    text-decoration: none !important;
    box-shadow: inset 0 calc(var(--thickBorder) * -1) calc(var(--thickBorder) * -1) var(--black);
}

.sidebarLinkActive {
    font-weight: bold;
}

.main {
    grid-column: 1 / -1;
    padding: 4rem 0.5rem 0;
}

.footer {
    grid-column: 1 / -1;
    padding: 0.5rem;
    align-self: end;
    margin-top: 2rem;
}

@media (min-width: 600px) {
    .main {
        grid-column: 3;
        padding: 1rem 0 0;
        min-width: 0;
    }

    .footer {
        grid-column: 3;
        padding: 0;
        margin-bottom: 1rem;
    }
}

@media (max-width: 599px) {
    .topRightMenu {
        margin-bottom: 1rem;
    }
}

@media (min-width: 600px) {
    .topRightMenu {
        position: fixed;
        top: 1rem;
        right: 1rem;
        z-index: 1;
    }
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    --backgroundDekoSize: 40vw;
    --backgroundDekoStripeWidth: calc(var(--backgroundDekoSize) / 9);
}

.background:before,
.background:after {
    position: absolute;
    content: '';
    height: calc(var(--backgroundDekoSize) * 2);
    width: var(--backgroundDekoSize);
    z-index: -1;
}

.background:before {
    top: 15vw;
    left: 2vw;
    transform: rotate(45deg);
    transform-origin: top left;
    background-image: repeating-linear-gradient(
        to left,
        var(--colorLangB),
        var(--colorLangB) var(--backgroundDekoStripeWidth),
        transparent var(--backgroundDekoStripeWidth),
        transparent calc(var(--backgroundDekoStripeWidth) * 2)
    );
}

.background:after {
    bottom: -50vw;
    right: -15vw;
    transform: translateX(40%) rotate(-45deg);
    transform-origin: center;
    background-image: repeating-linear-gradient(
        to left,
        var(--colorLangA),
        var(--colorLangA) var(--backgroundDekoStripeWidth),
        transparent var(--backgroundDekoStripeWidth),
        transparent calc(var(--backgroundDekoStripeWidth) * 2)
    );
}
