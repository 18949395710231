body {
    margin: 0;
    font-family: var(--monospaceFont);
    color: var(--black);
}

h1 {
    font-size: var(--fontSizeXXL);
}

h2 {
    font-size: var(--fontSizeL);
}

h3 {
    font-size: var(--fontSizeM);
}

a {
    color: inherit;
}

p {
    line-height: var(--lineHeightParagraph);
}

[lang='de'] p,
p[lang='de'] {
    hyphens: auto;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

.firebase-emulator-warning {
    display: none;
}
