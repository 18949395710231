.illustration {
    width: 100%;
    height: auto;
}

.body {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.warning {
    font-family: var(--paragraphFont);
    line-height: var(--lineHeightParagraph);
    margin-top: 0;
}

.imageCredit {
    font-size: var(--fontSizeS);
    margin-top: 0.5rem;
    font-style: italic;
}
