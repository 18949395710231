.columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
}

.narrower {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

@media (max-width: 1400px) {
    .columns {
        grid-gap: 3rem;
    }
}

@media (max-width: 1300px) {
    .columns {
        grid-gap: 2rem;
    }
}

@media (max-width: 1000px) {
    .columns {
        grid-template-columns: 1fr;
    }

    .reverseOnMobile > :nth-child(2) {
        grid-row: 1;
    }
}

.columns > * {
    min-width: 0;
}

.columnHeading {
    margin-bottom: 2rem;
    margin-top: 4rem;
}

.columnHeading:first-child {
    margin-top: 0;
}

.singleColumn {
    max-width: var(--singleColumnWidth);
}

.columnSection + .columnSection {
    margin-top: 4rem;
}
