.overlay {
    all: unset;

    --padding: 2rem;
    --lang-color: var(--orange);
    --logo-size: 6rem;

    background: var(--white);
    display: grid;
    grid-template-columns: 1fr;
    overflow: scroll;
    align-items: center;
    justify-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.overlay::backdrop {
    all: unset;
}

@supports (backdrop-filter: blur(20px)) {
    .overlay {
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(20px);
    }
}

@media (max-width: 500px) {
    .overlay {
        --padding: 1rem;
    }
}

.overlayInner {
    padding: var(--padding);
    width: min(600px, 100vw);
}

.overlayInner.wide {
    width: min(800px, 100vw);
}

.overlayInner.wider {
    width: min(1200px, 100vw);
}

.title {
    line-height: var(--lineHeightParagraph);
    margin-top: 0;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.closeButton {
    --background: var(--black);
    flex: 0 0 auto;
    position: relative;
    border: none;
    height: 3rem;
    width: 3rem;
    background: transparent;
    cursor: pointer;
    border-radius: 50%;
}

.closeButton:hover {
    --background: var(--orange);
}

.closeButton:before,
.closeButton:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    background: var(--background);
    height: 0.3rem;
    width: calc(100% - 0.5rem);
}

.closeButton:before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.closeButton:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.logo {
    width: 100%;
    height: var(--logo-size);
    object-fit: contain;
    margin: calc(var(--padding) * -0.5) 0 calc(var(--padding) * 0.5);
}

.noScroll {
    overflow: hidden !important;
}
