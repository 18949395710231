.link {
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.heading {
    font-size: inherit;
    line-height: var(--lineHeightParagraph);
    margin: 0;
    padding: 1rem;
    background: var(--orange);
    color: var(--blackDark);
}

.image {
    width: 100%;
    height: auto;
    margin-right: 1rem;
    display: block;
    max-height: 15rem;
    object-fit: cover;
    background: var(--orange);
}

.body {
    padding: 1rem;
}

.excerpt p {
    margin: 0;
    font-family: var(--paragraphFont);
}

.article {
    background: var(--white);
    box-shadow: 0 0 0 var(--thickBorder) var(--orange);
    cursor: pointer;
}

.article:hover {
    background: var(--orange);
}

.date {
    margin: 0 0 0.5rem;
    font-style: italic;
}

.clickForMore {
    margin-top: 0.5rem;
    font-style: italic;
    font-family: var(--paragraphFont);
    display: inline-block;
}

@media (max-width: 450px) {
    .image {
        max-height: 100vw;
    }
}
