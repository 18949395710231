.headingMacht {
    color: var(--green);
    font-weight: bold;
}

.headingSprache {
    color: var(--pink);
    font-weight: bold;
}

.claim {
    font-weight: bold;
    font-size: var(--fontSizeL);
    max-width: var(--singleColumnWidth);
}

@media (max-width: 600px) {
    .claim {
        font-size: var(--fontSizeM);
    }
}
