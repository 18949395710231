.container {
    --borderColor: var(--white);
    padding: 1rem;
    background: var(--lang-color);
    border: var(--thickBorder) solid var(--borderColor);
    font-style: italic;
    font-family: var(--paragraphFont);
    font-weight: 200;
    line-height: var(--lineHeightParagraph);
    color: var(--fontColorOnColor);
}

@media (max-width: 1200px) {
    .container {
        padding: 0.5rem;
    }
}

.container strong {
    font-weight: 500;
}
